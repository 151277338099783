import {useState} from "react";
import downArrow from "../assets/icons/down-arrow.svg"
import upArrow from "../assets/icons/up-arrow.svg"
import {useNavigate} from "react-router-dom";
import {PropertyTypology} from "../constants/property.typology.ts";
import searchIcon from "../assets/icons/search-icon.svg";
import provinces from "../assets/json/provincie_italiane.json"
import {useTranslation} from "react-i18next";
import {PropertyConfidentialNegotiation} from "../constants/property.confidentialNegotiation.ts";

export default function HomePropertySearchFormMobile() {
    const toggleAccordion = () => setIsOpen(!isOpen);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [country, setCountry] = useState('ITALIA');
    const [province, setProvince] = useState('');
    const [typology, setTypology] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [disablePrice, setDisablePrice] = useState(false);
    const [confidentialNegotiation, setConfidentialNegotiation] = useState('');

    const handleClick = () => {
        const queryParams = new URLSearchParams();

        if (typology) queryParams.append("typology", typology);
        if (province) queryParams.append("province", province);

        if (!confidentialNegotiation) {
            if (minPrice) queryParams.append("minPrice", minPrice);
            if (maxPrice) queryParams.append("maxPrice", maxPrice);
        } else {
            queryParams.append("confidentialNegotiation", confidentialNegotiation);
        }

        navigate(`/properties?${queryParams.toString()}`);
    };

    const handleMinPriceChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, ""); // Rimuove tutto tranne i numeri
        const formattedValue = new Intl.NumberFormat("it-IT").format(rawValue); // Formatta il numero con separatori italiani
        setMinPrice(formattedValue);
    };

    const handleMaxPriceChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, ""); // Rimuove tutto tranne i numeri
        const formattedValue = new Intl.NumberFormat("it-IT").format(rawValue); // Formatta il numero con separatori italiani
        setMaxPrice(formattedValue);
    };

    const handleKeyDown = (e) => {
        // Blocca l'input di caratteri non numerici (ma consente backspace, delete, etc.)
        if (
            ["e", "E", "+", "-"].includes(e.key) || // Blocca caratteri invalidi
            (e.key === "." && e.target.value.includes(".")) // Blocca più di un punto decimale
        ) {
            e.preventDefault();
        }
    };

    const handleConfidentialNegotiationChange = (e) => {
        const value = e.target.value;
        setConfidentialNegotiation(value);
        setDisablePrice(value === 'true');
        if (value === 'true') {
            setMinPrice("");
            setMaxPrice("");
        }
    }

    return (
        <div className="w-full px-[40px] mt-5">
            <div className="py-[20px]">
                <button
                    onClick={toggleAccordion}
                    className="font-raleway font-light text-[16px] tracking-[1.6px] uppercase w-full text-gray"
                >
                    <div className="flex justify-center">
                        <span className="font-light me-[74px]">
                            {t('home.propertySearchForm.findProperty')}
                        </span>
                        <img src={isOpen ? upArrow : downArrow} alt="search"/>
                    </div>
                </button>

                {isOpen && (
                    <div className="mt-[63px]">
                        <input
                            type="text"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            placeholder={t('home.propertySearchForm.labels.country')}
                            className="w-full"
                            disabled={true}
                        />
                        <select
                            value={province}
                            onChange={(e) => setProvince(e.target.value)}
                            className="w-full md:w-auto mt-[42px]"
                        >
                            <option value={null}>
                                {t('home.propertySearchForm.labels.location')}
                            </option>
                            {Object.entries(provinces).map(([key, province]) => (
                                <option key={key} value={province.code}>
                                    {province.name}
                                </option>
                            ))}
                        </select>
                        <select
                            value={typology}
                            onChange={(e) => setTypology(e.target.value)}
                            className="w-full mt-[42px]"
                        >
                            <option value={null}>
                                {t('home.propertySearchForm.labels.typology')}
                            </option>
                            {Object.entries(PropertyTypology).map(([key, typology]) => (
                                <option key={key} value={typology}>
                                    {t(`property.typologies.${typology}`)}
                                </option>
                            ))}
                        </select>
                        <div className="flex items-center justify-between border-b border-b-white mt-[42px]">
                            <select
                                value={confidentialNegotiation}
                                onChange={handleConfidentialNegotiationChange}
                                className="w-full"
                            >
                                <option value={null}>
                                    {t('home.propertySearchForm.labels.confidentialNegotiation')}
                                </option>
                                {Object.entries(PropertyConfidentialNegotiation).map(([key, typology]) => (
                                    <option key={key} value={typology}>
                                        {t(`property.confidentialNegotiations.${typology}`)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex items-end">
                            <input
                                type="text"
                                value={minPrice}
                                onChange={handleMinPriceChange}
                                onKeyDown={handleKeyDown}
                                placeholder={t('home.propertySearchForm.labels.minPrice')}
                                className={`w-full no-spinner mt-[42px]
                                ${disablePrice ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={disablePrice}
                            />
                            <span className={'font-raleway text-[18px] text-secondary border-b pb-[15px]'}>€</span>
                        </div>
                        <div className="flex items-end mt-[42px]">
                            <input
                                type="text"
                                value={maxPrice}
                                onChange={handleMaxPriceChange}
                                onKeyDown={handleKeyDown}
                                placeholder={t('home.propertySearchForm.labels.maxPrice')}
                                className={`w-full no-spinner
                                ${disablePrice ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={disablePrice}
                            />
                            <span className={'font-raleway text-[18px] text-secondary border-b pb-[15px]'}>€</span>
                        </div>
                        <button
                            onClick={handleClick}
                            className="btn-form mt-[42px] w-full"
                        >
                            <div className="flex justify-between">
                                <span>
                                    {t('home.propertySearchForm.find')}
                                </span>
                                <img src={searchIcon} alt="search"/>
                            </div>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
