import {useNavigate} from "react-router-dom";

import PropertyImage from "./PropertyImage";
import {useTranslation} from "react-i18next";

export default function PropertiesList ({properties}) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    if (!properties || properties.length === 0) {
        return <h4 className="h4-secondary">{t('properties.noProperty')}</h4>;
    }

    const onPropertyClick = (id) => {
        navigate(`/property/${id}`)
    }

    return (
        <div className="grid grid-cols-2 gap-4">
            {properties.map((property, index) => (
                <div
                    key={index}
                    className="flex flex-col items-center mb-20 cursor-pointer"
                    onClick={() => onPropertyClick(property.id)}
                >
                    <PropertyImage
                        photos={property.photos}
                        alt={`Photo for ${property.reference}`}
                    />
                    <span
                        className="font-raleway text-left text-[18px] tracking-[1.8px] text-gray w-full pb-10 uppercase">
                            Ref: {property.reference}
                    </span>
                    <span
                        className="font-serifDisplay text-left text-[35px] text-lightGray border-b border-gray w-full pb-10 capitalize">
                           {t(`property.typologies.${property.propertyTypology}`)}
                    </span>
                    <div className="flex justify-between w-full mt-10">
                        <div className="flex">
                            <span className="font-raleway uppercase text-gray font-light tracking-[1.8px]">
                                {property.city ?? ''}
                            </span>
                            <span className="font-raleway uppercase text-gray font-light tracking-[1.8px]">
                                {(property.city && property.province) ? '\u00a0-\u00a0' : ''}
                            </span>
                            <span className="font-raleway uppercase text-gray font-light tracking-[1.8px]">
                                {property.province ?? ''}
                            </span>
                        </div>
                        <span className="font-raleway uppercase text-gray font-light tracking-[1.8px]">
                            {property.price ? `${property.price.toLocaleString('it-IT')} €` : t(`property.confidentialNegotiation`)}
                        </span>
                    </div>
                    <span
                        className="text-left font-raleway text-gray font-light tracking-[1.8px] w-full">{property.area ? `${property.area.toLocaleString('it-IT')} mq` : ''}</span>
                </div>
            ))}
        </div>
    )
}
