import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function OffMarketPropertiesList({properties}) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    if (!properties || properties.length === 0) {
        return <h4 className="h4-secondary">{t('offMarketProperty.noProperty')}</h4>;
    }

    const onPropertyClick = (id) => {
        navigate(`/off-market-property/${id}`)
    }

    return (
        <div className="flex flex-col">
            {properties?.map((property, index) => (
                <div
                    key={index}
                    className="flex flex-col items-center mb-20 cursor-pointer"
                    onClick={() => onPropertyClick(property.id)}
                >
                    <span
                        className="font-raleway text-left text-[18px] tracking-[1.8px] text-gray w-full pb-[37px] uppercase">
                            Ref: {property.reference}
                    </span>
                    <span
                        className="font-serifDisplay text-left text-[30px] pb-[37px] text-lightGray border-0 md:border-b border-gray w-full capitalize">
                            {t(`property.typologies.${property.propertyTypology}`)}
                    </span>
                    <div className="hidden md:flex w-2/3 mt-10 self-end justify-between">
                        <span className="font-raleway uppercase text-gray font-light tracking-[1.8px]">
                            {property.city ?? '-'}
                        </span>
                        <span
                            className="font-raleway text-gray font-light tracking-[1.8px]">{property.area ? `${property.area} mq` : '-'}
                        </span>
                        <span className="font-raleway uppercase text-gray font-light tracking-[1.8px] text-nowrap">
                            {property?.price ? `${property?.price?.toLocaleString('it-IT')} €` : t(`property.confidentialNegotiation`)}
                        </span>
                    </div>
                    <div className="md:hidden grid text-start w-full grid-cols-1 space-y-10">
                        <span className="font-raleway uppercase text-gray font-light tracking-[1.8px] text-nowrap">
                            {t('offMarketProperty.price')}
                        </span>
                        <span className="font-raleway uppercase text-gray font-light tracking-[1.8px]">
                            {property.city ?? '-'}
                        </span>
                        <span
                            className="font-raleway text-gray font-light tracking-[1.8px] w-full pb-11 border-b">{property.area ? `${property.area} mq` : '-'}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}
