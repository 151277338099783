import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import searchIcon from "../assets/icons/search-icon.svg";
import {PropertyTypology} from "../constants/property.typology.ts";
import provinces from "../assets/json/provincie_italiane.json"
import {PropertyConfidentialNegotiation} from "../constants/property.confidentialNegotiation.ts";

export default function HomePropertySearchForm() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [country, setCountry] = useState('ITALIA');
    const [province, setProvince] = useState('');
    const [typology, setTypology] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [disablePrice, setDisablePrice] = useState(false);
    const [confidentialNegotiation, setConfidentialNegotiation] = useState('');

    const handleClick = () => {
        const queryParams = new URLSearchParams();

        if (typology) queryParams.append("typology", typology);
        if (province) queryParams.append("province", province);

        if (!confidentialNegotiation) {
            if (minPrice) queryParams.append("minPrice", minPrice);
            if (maxPrice) queryParams.append("maxPrice", maxPrice);
        }

        queryParams.append("confidentialNegotiation", confidentialNegotiation);

        navigate(`/properties?${queryParams.toString()}`);
    };

    const handleMinPriceChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, ""); // Rimuove tutto tranne i numeri
        const formattedValue = rawValue > 0 ? new Intl.NumberFormat("it-IT").format(rawValue) : ''; // Formatta il numero con separatori italiani
        setMinPrice(formattedValue);
    };

    const handleMaxPriceChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, ""); // Rimuove tutto tranne i numeri
        const formattedValue = rawValue > 0 ? new Intl.NumberFormat("it-IT").format(rawValue) : ''; // Formatta il numero con separatori italiani
        setMaxPrice(formattedValue);
    };

    const handleKeyDown = (e) => {
        // Blocca l'input di caratteri non numerici (ma consente backspace, delete, etc.)
        if (
            ["e", "E", "+", "-"].includes(e.key) || // Blocca caratteri invalidi
            (e.key === "." && e.target.value.includes(".")) // Blocca più di un punto decimale
        ) {
            e.preventDefault();
        }
    };

    const handleConfidentialNegotiationChange = (e) => {
        const value = e.target.value;
        setConfidentialNegotiation(value);
        setDisablePrice(value === 'true');
        if (value === 'true') {
            setMinPrice("");
            setMaxPrice("");
        }
    }

    return (
        <div className="grid grid-cols-4 gap-y-10 gap-x-[40px]">
            <input
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder={t('home.propertySearchForm.labels.country')}
                className="w-full md:w-auto"
                disabled={true}
            />
            <select
                value={province}
                onChange={(e) => setProvince(e.target.value)}
                className="w-full md:w-auto"
            >
                <option value={null}>{t('home.propertySearchForm.labels.location')}</option>
                {Object.entries(provinces).map(([key, province]) => (
                    <option key={key} value={province.code}>
                        {province.name}
                    </option>
                ))}
            </select>
            <select
                value={typology}
                onChange={(e) => setTypology(e.target.value)}
                className="w-full md:w-auto"
            >
                <option value={null}>{t('home.propertySearchForm.labels.typology')}</option>
                {Object.entries(PropertyTypology).map(([key, typology]) => (
                    <option key={key} value={typology}>
                        {t(`property.typologies.${typology}`)}
                    </option>
                ))}
            </select>
            <select
                value={confidentialNegotiation}
                onChange={handleConfidentialNegotiationChange}
                className="w-full md:w-auto"
            >
                <option value={""}>
                    {t('home.propertySearchForm.labels.confidentialNegotiation')}
                </option>
                {Object.entries(PropertyConfidentialNegotiation).map(([key, typology]) => (
                    <option key={key} value={typology}>
                        {t(`property.confidentialNegotiations.${typology}`)}
                    </option>
                ))}
            </select>
            <div className={`flex items-end 
            ${disablePrice ? 'opacity-50 cursor-not-allowed' : ''}`}>
                <input
                    type="text"
                    value={minPrice}
                    onChange={handleMinPriceChange}
                    onKeyDown={handleKeyDown}
                    placeholder={t('home.propertySearchForm.labels.minPrice')}
                    className="w-full disabled:cursor-not-allowed"
                    disabled={disablePrice}
                />
                <span className={'font-raleway md:text-[16px] text-secondary border-b pb-[15px]'}>€</span>
            </div>
            <div className={`flex items-end 
            ${disablePrice ? 'opacity-50 cursor-not-allowed' : ''}`}>
                <input
                    type="text"
                    value={maxPrice}
                    onChange={handleMaxPriceChange}
                    onKeyDown={handleKeyDown}
                    placeholder={t('home.propertySearchForm.labels.maxPrice')}
                    className="w-full disabled:cursor-not-allowed"
                    disabled={disablePrice}
                />
                <span className={'font-raleway md:text-[16px] text-secondary border-b pb-[15px]'}>€</span>
            </div>
            <div className={'grid grid-cols-subgrid col-span-2'}>
                <button
                    onClick={handleClick}
                    className="btn-form w-full md:!text-[16px] col-start-3"
                >
                    <div className="flex justify-between">
                        <span>{t('home.propertySearchForm.find')}</span>
                        <img src={searchIcon} alt="search"/>
                    </div>
                </button>
            </div>
        </div>
    );
}
